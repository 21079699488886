<template>
  <div
    class="
      d-flex
      ml-lg-auto
      my-4 my-lg-0
      justify-content-center
      bg-light
      px-3
      py-2
      border-0
      rounded-pill
    "
  >
    <div class="d-flex align-items-center ml-5 my-1">
      <span class="mr-9">
        <i class="fa fa-2x fa-calendar-day text-gray"></i>
      </span>
    </div>
    <div v-if="type === 'full'" class="d-flex align-items-center ml-5 my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="font-weight-bolder font-size-sm">{{
          $t("HOLIDAY.DAY")
        }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ getDay() }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center ml-5 my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="font-weight-bolder font-size-sm">{{
          $t("STATISTICS.MONTH")
        }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ getMonth() }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center ml-5 my-1 mr-5">
      <div class="d-flex flex-column text-dark-75">
        <span class="font-weight-bolder font-size-sm">{{
          $t("STATISTICS.YEAR")
        }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ getYear() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "EmployeeAttendanceManageDate",
  props: {
    date: Date,
    type: String,
  },
  methods: {
    getDay() {
      return dayjs(this.date).format("DD");
    },
    getMonth() {
      return this.date.toLocaleString("default", { month: "long" });
    },
    getYear() {
      return this.date.getFullYear();
    },
  },
};
</script>

<style scoped></style>
